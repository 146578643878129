import emailjs from 'emailjs-com';
import {MusicGenre} from "@/models/FormModels";

export const sendEmailConfirmation = async(formData: Record<string, any>) => {
  const res = await emailjs.send(process.env.VUE_APP_SERVICE_ID, "template_jiyxyap", {
    firstname: formData.user.firstname,
    lastname: formData.user.lastname,
    email: formData.user.email,
    phone: formData.user.phone,
    occasion: formData.event.occassion.name,
    date: (new Date(formData.event.date)).toLocaleDateString('de-DE'),
    guests: formData.event.numberGuests.displayName,
    duration: formData.event.duration.displayName,
    music: formData.event.music?.map((music: MusicGenre) => music.displayName) ?? "",
    information: formData.user.information || "no further information",
    locationName: formData.location.locationName,
    zip: formData.location.zipCode,
    city: formData.location.city,
    technology: formData.location.eventTechnology.name,
    subject: `Eingangsbestätigung der Anfrage für den ${(new Date(formData.event.date)).toLocaleDateString('de-DE')}`,
    salutation: "Herzlichen Dank für deine Anfrage!",
    name: formData.user.firstname + " " + formData.user.lastname,
    message: `Hallo ${formData.user.firstname}, \n\num alle weiteren Details zu besprechen trage dich bitte für ein kostenloses Erstgespräch ein. \n\n`,
    url: 'https://calendly.com/djdanieldaub/beratungsgespraech',
    url_name: 'Termin vereinbaren'
  }, process.env.VUE_APP_USER_ID )
}