<template>
  <router-view/>
</template>

<style lang="scss">
@import "assets/style/main.scss";
@import "assets/style/locomotiveScroll.scss";
@import 'assets/style/dropdown.scss';
@import 'assets/style/chipsinput.scss';
@import 'assets/style/selectbutton.scss';

#app {
  color: var(--main-text);
  background-color: var(--main-bg);
  overflow: clip;
}

</style>
